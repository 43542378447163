$colors: (
  request_created: #e59500,
  documents: #e5950099,
  updated: #002a4d,
  report: #002a4d99,
  checkout: #002a4d99,
  cancelled: #d6222a,
  cancel_expected: #d6222a99,
  issued: #87a330,
);

@each $name, $color in $colors {
  .dropdown-#{$name} {
    height: 24px;
    width: 120px;

    div {
      background-color: #{$color} !important;

      span {
        color: #ffffff !important;
      }
    }

    span > span {
      color: #ffffff !important;
    }
  }
}
