.description {
  font: {
    size: 12px;
    weight: 300;
    family: 'SF Pro Display', sans-serif;
  }

  color: #ec9596;
}

.companyName {
  font: {
    size: 16px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }

  color: #b2b2b2;
}

.time {
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }

  color: #002a4d;
}
