.labelTitle {
  font: {
    family: 'Lato', sans-serif;
    size: 16px;
    weight: 700;
  }
}

.description {
  font: {
    size: 12px;
    weight: 300;
    family: 'SF Pro Display', sans-serif;
  }
  color: #ec9596;
}

.companyName {
  font: {
    size: 16px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
  color: #b2b2b2;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px 24px;
  gap: 8px;
  background-color: #d6222a;
}

.buttonText {
  color: #ffffff;
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
}

.buttonIcon {
  color: #ffffff;
}

.error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
}

.time {
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
  color: #002a4d;
}

.domesticTag {
  border-color: #e59500;
  color: #e59500;
  padding: 1px 7px;
}

.transportationTag {
  border-color: #87a330;
  color: #87a330;
  padding: 1px 7px;
}

.abroadTag {
  border-color: #002a4d;
  color: #002a4d;
  padding: 1px 7px;
}
