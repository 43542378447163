.headerContainer {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 28px);
  height: calc(66px - 24px);
  padding: 12px 24px 12px 4px;
  background-color: #001628;
  border: {
    bottom: 0.3px;
    color: #ffffff;
  }
}

.headerLogoContainer {
  cursor: pointer;
  padding: 0px 24px;
}

.rightSide {
  padding: 0px 0px;
}

.verticalLine {
  height: 50%;
  background-color: #ffffff;
}

.firstLetterContainer {
  width: 27px;
  height: 27px;
  border-radius: 15px;
  background-color: #ec9596;
}

.content {
  font: {
    size: 16px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
  color: #ffffff;
}

.dropdown {
  padding: 15px 0 0 0;
  width: 160px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 4px 4px 4px;
    border-radius: 0px !important;
    background-color: #001628 !important;

    a {
      color: #ffffff !important;
    }
  }
}
