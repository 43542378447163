.container {
  min-height: calc(100vh - 102px);
  padding: 36px 0px 0px 0px;
  border-right: 0.5px solid;
  background-color: #001628;
}

.menu {
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
  margin: 0px;
}
