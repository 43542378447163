.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal {
  background: #fafafa;
  border: {
    radius: 15px;
    color: #002a4d;
    width: 1px;
    style: solid;
  }
  width: 600px;
  max-width: 100%;

  &.open {
    animation: modalOpenAnimation 0.3s ease forwards;
  }

  @keyframes modalOpenAnimation {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #d6222a;
  color: #fafafa;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font: {
    size: 24px;
    weight: 600;
    family: 'SF Pro Display', sans-serif;
  }
  padding: 24px 24px 12px 24px;
}

.modalBody {
  background-color: #fafafa;
  padding: 24px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  background-color: #fafafa;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 0px 24px 12px 24px;
}

.cancelButton,
.submitButton {
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.cancelButton {
  background-color: #ffffff;
  color: #313131;
  border-color: #d6222a;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}

.submitButton {
  background-color: #d6222a;
  color: #ffffff;
}

.title {
  margin-top: 0px;
  margin-bottom: 10px;
}
