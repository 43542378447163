.widthOneQuarter {
  width: 25%;
}

.widthOneThird {
  width: 33%;
}

.widthHalf {
  width: 50%;
}
