.datePicker {
  border-color: #313131;
  color: #313131;

  input::placeholder {
    color: #313131 !important;
  }
  span {
    color: #313131;
  }
}

.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px 24px;
  gap: 8px;
  background-color: #d6222a;

  &:hover {
    border: 1px solid #d6222a !important;
    background-color: #ffffff !important;
    color: #d6222a !important;

    .buttonText,
    .buttonIcon {
      color: #d6222a;
    }
  }
}

.bindButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px 24px;
  gap: 8px;
  background-color: #ec9596;

  &:hover {
    border-color: #ec9596 !important;

    .buttonText {
      color: #ec9596 !important;
    }
  }
}

.displayButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px 24px;
  gap: 8px;
  background-color: #002a4d;

  &:hover {
    border: 1px solid #002a4d !important;
    background-color: #ffffff !important;
    color: #002a4d !important;

    .buttonText {
      color: #002a4d;
    }
  }
}

.buttonText {
  color: #ffffff;
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
}

.buttonIcon {
  color: #ffffff;
}

.popconfirmTitle {
  text-align: center;
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.icon {
  cursor: pointer;
}

.domesticTag {
  border-color: #e59500;
  color: #e59500;
  padding: 1px 7px;
}

.transportationTag {
  border-color: #87a330;
  color: #87a330;
  padding: 1px 7px;
}

.abroadTag {
  border-color: #002a4d;
  color: #002a4d;
  padding: 1px 7px;
}
