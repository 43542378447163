.leftSideContainer {
  width: 100%;
}

.leftSide {
  padding: 10px 120px;
  background-color: #fcfcfc;
}

.logoText {
  font: {
    size: 28px;
    weight: 500;
    family: 'SF Pro Display', sans-serif;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.fieldLabel {
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
  color: #ec9596;
}

.fieldInput {
  border: {
    width: 0.5px;
    radius: 6px;
    color: #a2abb2;
  }
  padding: 4px 12px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background-color: #d6222a;
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
  color: #ffffff;
}

.button:hover {
  border-color: #d6222a !important;
  background-color: #ffffff !important;
  color: #d6222a !important;
}

.image {
  height: 100vh;
}

.description {
  text-align: center;
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
}
