.container {
  padding: 20px 10px 10px;
  width: calc(100vw - 298px);
}

.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px 24px;
  gap: 8px;
  background-color: #d6222a;

  &:hover {
    border: 1px solid #d6222a !important;
    background-color: #ffffff !important;
    color: #d6222a !important;

    .buttonText,
    .buttonIcon {
      color: #d6222a;
    }
  }
}

.buttonText {
  color: #ffffff;
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
}

.buttonIcon {
  color: #ffffff;
}

.bottomContainer {
  padding: 16px;
  gap: 32px;
}

.customCheckbox {
  span {
    cursor: default !important;
    color: #000000 !important;
  }
}

.divider {
  height: auto;
  margin-top: -32px;
  margin-bottom: -32px;
}

.treeContainer {
  padding: 16px;
  width: 100%;
}
