.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px 24px;
  gap: 8px;
  background-color: #e59500;

  &:hover {
    border: 1px solid #e59500 !important;
    background-color: #ffffff !important;
    color: #e59500 !important;

    .buttonText,
    .buttonIcon {
      color: #e59500;
    }
  }
}

.buttonText {
  color: #ffffff;
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
}

.buttonIcon {
  color: #ffffff;
}
