.container {
  width: calc(100% - 48px);
  padding: 12px 24px;
}

.leftLine {
  height: 1px;
  width: 30%;
  background: linear-gradient(
    90deg,
    rgba(236, 149, 150, 0.1) 10%,
    rgba(236, 149, 150, 1) 100%
  );
}

.name {
  color: #313131;
  font: {
    size: 24px;
    weight: 600;
    family: 'SF Pro Display', sans-serif;
  }
}

.rightLine {
  height: 1px;
  width: 30%;
  background: linear-gradient(
    270deg,
    rgba(236, 149, 150, 0.1) 10%,
    rgba(236, 149, 150, 1) 100%
  );
}
