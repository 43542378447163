.table {
  thead > tr > th {
    font: {
      size: 14px;
      weight: 900;
      family: 'SF Pro Display', sans-serif;
    }
    background-color: #e6f7ff !important; // Başlık arka plan rengi
  }

  tbody > tr > td {
    font: {
      size: 14px;
      weight: 400;
      family: 'SF Pro Display', sans-serif;
    }
  }

  tbody > tr > td:last-child {
    background-color: #f0f0f0 !important;
  }

  tbody > tr:last-child > td {
    background-color: #f0f0f0 !important; // Son satır arka plan rengi
  }

  tbody > tr:last-child > td:last-child {
    background-color: #f7e6e6 !important; // Son satırın son hücresinin arka plan rengi
  }
}

.noteContainer {
  padding: 20px 0px;

  .note {
    font: {
      size: 14px;
      weight: 400;
      family: 'SF Pro Display', sans-serif;
    }
    color: #ec9596;
  }
}

.timestamp {
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
  color: #002a4d;
}

.labelTitle {
  font: {
    family: 'Lato', sans-serif;
    size: 16px;
    weight: 700;
  }
}

.input {
  width: 200px;
}
