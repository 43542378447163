.container-expanded {
  padding: 10px;
  transition: width 0.2s;
  width: calc(100vw - 278px);
}

.container-collapsed {
  padding: 10px;
  transition: width 0.2s;
  width: calc(100vw - 140px);
}
