.container {
  width: 450px;
}

.select {
  width: 100%;
}

.label {
  font: {
    size: 12px;
    weight: 500;
    family: 'SF Pro Display', sans-serif;
  }
}

.value {
  font: {
    size: 12px;
    weight: 300;
    family: 'SF Pro Display', sans-serif;
  }
}

.button {
  width: 48%;
}
