.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px 24px;
  gap: 8px;
  background-color: #ec9596;

  &:hover {
    border-color: #ec9596 !important;

    .text,
    .icon {
      color: #ec9596 !important;
    }
  }
}

.text {
  color: #ffffff;
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
}

.icon {
  color: #ffffff;
}

.uploadName {
  color: #888888 !important;
}
