.labelValue {
  color: #b2b2b2;
  font: {
    size: 16px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
}

.documentDescription {
  color: #ec9596;
  font: {
    size: 14px;
    weight: 400;
    family: 'SF Pro Display', sans-serif;
  }
}
