.label {
  font: {
    family: 'Lato', sans-serif;
    size: 16px;
    weight: 700;
  }
}

.error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
}

.customCheckbox {
  background-color: transparent !important;
}
