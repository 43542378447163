.table {
  table {
    width: 100%;
    background-color: #fcfcfc;
    border: 1px solid #b2b2b2;
    border-radius: 6px;
    border-collapse: collapse;
  }
  thead > tr > th {
    color: #1e1e1e;
    font: {
      size: 14px;
      weight: 800;
      family: 'SF Pro Display', sans-serif;
    }
    background-color: #ffffff !important;
    border-bottom: 25px solid #b2b2b2;
  }
  tbody > tr > td {
    color: #313131;
    font: {
      size: 14px;
      weight: 400;
      family: 'SF Pro Display', sans-serif;
    }
    background-color: #ffffff !important;
    border-bottom: 1px solid #b2b2b255;
  }
}

.inputContainer {
  padding: 10px;
}

.subTable {
  table {
    width: 100%;
    background-color: #313131;
  }
  thead > tr > th {
    color: #1e1e1e;
    font: {
      size: 14px;
      weight: 800;
      family: 'SF Pro Display', sans-serif;
    }
    background-color: #f0f0f0 !important;
  }
  tbody > tr > td {
    color: #1e1e1e;
    font: {
      size: 14px;
      weight: 400;
      family: 'SF Pro Display', sans-serif;
    }
    background-color: #f0f0f0 !important;
  }
}
